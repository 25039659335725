<template>
  <section class="modifiers">
    <Modifiers />
  </section>
</template>

<script>
// @ is an alias to /src
import Modifiers from '@/components/Modifiers.vue'

export default {
  name: 'ModifiersView',
  components: {
    Modifiers
  }
}
</script>

<style lang="scss">

 .add {
  float: right;
  margin-right: 7px;
  background-color: #afa;
  border: 1px solid #999;
  border-radius: 2px;
  padding: 3px 7px 3px 7px;
 }
 .delete {
  background-color: #fcc;
  border: 1px solid #999;
  border-radius: 2px;
  padding: 3px 7px 3px 7px;
  float: right;
  }

 td select {
   height: 23px;
   width: 460px
 } 
 
 .modifier_table {
  min-width: 600px;
  border-radius: 7px;
  border: 1px solid #999;
  margin-bottom: 15px; 
  overflow: hidden;
}
 .modifier_table table {
  width: 100%;
  margin-bottom: 15px;
}

.orders {
  
  table {
    th {
      width: 40px;
      &:nth-child(5) {
        width: auto;
      }
    }
    td {
      &:nth-child(5) {
        padding: 0;
        width: auto;
      }
    }

    table.order-items-header, table.order-items-body {
      table-layout: fixed;
      td, th {
        width: 80px;
        padding: 8px;
        overflow-wrap: anywhere;
      }
    }
    tr:nth-child(odd) {
      tr:nth-child(odd){
        background-color: #333;
        color: #fff;
        font-weight: bold;
      }
      tr:nth-child(even){
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
    tr:nth-child(even) {
      tr:nth-child(odd){
        background-color: #999;
        color: #fff;
        font-weight: bold;
      }
      tr:nth-child(even){
        background-color: #aaa;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
</style>
