<template>
 <input type="button" value="New Modifier" @click="newModifier()" class="newmodifier">
  <br>
  <br>
  <div v-for="modifier in modifiers" :key="modifier.id" class="modifier_table">
  <table>
  <tr>
  <th>{{ modifier.id }}</th>
  <th><input type="text" :value="modifier.name" @input.prevent.stop="updateName(modifier.id, $event.target.value)" class="name"/></th>
  <th>
    <div v-for="composition in ['single','multiple']" :key="modifier.id +'_' + composition">
       <input type="radio" :name="modifier.id + '_composition'" :value="composition" :checked="composition == modifier.composition" @change.prevent.stop="changeComposition(modifier.id,composition,$event.target.checked)">{{ composition }}
    </div>
</th>
  </tr>
  <tr>
  <td colspan="3" align="left">
  <b>Dishes:</b><br>
  <table>
    <tr v-for="dish in modifier.dishes" :key="modifier.id+'_'+dish[0]">
     <td>{{ dish[1] }}</td>
     <td align="left">{{ dish[2] }}</td>
     <td align="right">{{ dish[3]/100 }}</td>
     <td align="right" width="100px"><input type="button" value="Remove" @click="removeDish(modifier.id,dish[0])" class="delete"></td>
    </tr>
  </table>
  <select :name="'add_'+modifier.id" :id="'add_'+modifier.id">
    <option v-for="dish in filterDishes(dishes,modifier.dishes)" :key="dish.id" :value="dish.id">{{ dish.code }}: {{ dish.name }}</option>
  </select>
  <input type="button" value="Add" @click="addDish(modifier.id)" class="add">
  </td>
  </tr>
  </table>
</div>
</template>

<script>
import store from '@/store'
import debounce from "lodash.debounce"

const debounce_time = 1000

export default {
  name: 'Brands',
  data() {
    return {
      refreshKey: 0,
      search_name: "",
      search_slogan: "",
      search_pinned: "both"
    }
  },
  methods: {
    updateName: debounce( (modifier_id, name) => {
      window.socket.send(JSON.stringify(["update modifier", modifier_id, {name: name}]))
    }, debounce_time),

    changeComposition(modifier_id,composition, state) {
      if(state)
        window.socket.send(JSON.stringify(["update modifier", modifier_id, {composition: composition}]))
    },
    addDish(modifier_id) {
        let dish_id = document.getElementById('add_'+modifier_id).value
        window.socket.send(JSON.stringify(["modifier add dish", modifier_id, dish_id]))
    },
    removeDish(modifier_id,dish_id) {
        window.socket.send(JSON.stringify(["modifier remove dish", modifier_id, dish_id]))
    },
    filterDishes(available, used) {
     available = available.filter(function(item) { 
        for(let i=0; i<used.length; i++) {
          if(item.id == used[i][0]) return false;
        }
        return true;
       } )
     return available
    },
    newModifier() {

      window.socket.send(JSON.stringify(["insert modifier"]))
    }, 
    search(e){
       this.refreshKey++;
       console.log(e) 
    }
  },
  computed: {
    modifiers(){
      let modifiers = store.state.modifiers
      return modifiers
    },
    dishes() {
      let dishes = store.state.dishes
 
      dishes = dishes.filter(function(dish) {
          return !dish.browseable
      })


      return dishes

    }
  }
}
</script>

